<template>
  <v-card>
    <v-card-title>
      {{sportName}}
    </v-card-title>
    <v-card-text>
      <div>
        <v-btn 
          @click="$router.push(`/tiimi-admin/sports/${sportId}/tags`)" 
          text 
          small
        >
          Tags
        </v-btn>
        <v-btn 
          @click="$router.push(`/tiimi-admin/sports/${sportId}/templates`)" 
          text
          small
        >
          Templates
        </v-btn>
      </div>
    </v-card-text>
    <v-card flat class="">
      <router-view></router-view>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  created() {
    // this.initLeagueTags({league_id: this.$route.params.id, season: 'all'})
  },
  computed: {
    ...mapGetters('admin', [
      'leagues',
      'games',
      'sportById'
    ]),
    sportName() {
      return this.sportById(this.$route.params.id)?.sport_name
    },
    sportId() {
      return this.$route.params.id
    }
  },
  methods: {
    ...mapActions('admin', [
      'initSportTags'
    ])
  }
}
</script>